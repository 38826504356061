import React from 'react';

export const Github = ({ fillColor, className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    strokeLinejoin='round'
    strokeLinecap='round'
    strokeWidth='2'
    stroke={fillColor}
    fill='none'
    viewBox='0 0 32 32'
  >
    <g>
      <title>Layer 1</title>
      <path
        id='svg_1'
        d='m13.25,23.5134c-5,1.5 -5,-2.5 -7,-3m14,6l0,-3.87a3.37,3.37 0 0 0 -0.94,-2.61c3.14,-0.35 6.44,-1.54 6.44,-7a5.44,5.44 0 0 0 -1.5,-3.75a5.07,5.07 0 0 0 -0.09,-3.77s-1.18,-0.35 -3.91,1.48a13.38,13.38 0 0 0 -7,0c-2.73,-1.83 -3.91,-1.48 -3.91,-1.48a5.07,5.07 0 0 0 -0.09,3.77a5.44,5.44 0 0 0 -1.5,3.78c0,5.42 3.3,6.61 6.44,7a3.37,3.37 0 0 0 -0.94,2.58l0,3.87'
      />
      <rect strokeWidth='4' id='svg_2' height='32' width='32' y='0' x='0' />
    </g>
  </svg>
);
